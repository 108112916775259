import * as workflowTypes from '../workflows/workflows.types';
import * as accountTypes from '../accounts/accounts.types';
import * as loginTypes from '../login/login.types';
import * as wizardTypes from '../wizard/wizard.types';
import * as resourcesTypes from '../resources/resources.types';

import { ENQUEUE_SNACKBAR_REQUEST, CLOSE_SNACKBAR_REQUEST, REMOVE_SNACKBAR_REQUEST } from './ui.types';

export const initialState = {
  fullScreenLoading: 0,
  snackbarLoading: 0,
  dialogLoading: 0,
  notifications: []
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case workflowTypes.GET_EDGES_REQUEST:
    case workflowTypes.GET_NODES_REQUEST:
    case workflowTypes.CREATE_WORKFLOW_REQUEST:
    case workflowTypes.UPDATE_WORKFLOW_REQUEST:
    case workflowTypes.DELETE_WORKFLOW_REQUEST:
    case workflowTypes.DUPLICATE_WORKFLOW_REQUEST:
    case workflowTypes.GET_WORKFLOWS_REQUEST:
    case workflowTypes.DELETE_NODE_REQUEST:
    case accountTypes.EDIT_ORGANIZATION_REQUEST:
    case accountTypes.EDIT_TEAM_REQUEST:
    case accountTypes.CREATE_ORGANIZATION_REQUEST:
    case accountTypes.CREATE_TEAM_REQUEST:
    case accountTypes.CREATE_USER_REQUEST:
    case accountTypes.DISABLE_TEAM_REQUEST:
    case accountTypes.ADD_MEMBERS_REQUEST:
    case accountTypes.EDIT_USER_REQUEST:
    case accountTypes.CHANGE_USER_PASSWORD_REQUEST:
    case accountTypes.UPDATE_USER_REQUEST:
    case accountTypes.UPDATE_PASSWORD_REQUEST:
    case wizardTypes.PROCESS_WELLS_REQUEST:
    case resourcesTypes.DELETE_RESOURCE_REQUEST: {
      return {
        ...state,
        fullScreenLoading: state.fullScreenLoading + 1
      };
    }
    case workflowTypes.GET_EDGES_SUCCESS:
    case workflowTypes.GET_EDGES_FAILURE:
    case workflowTypes.GET_NODES_SUCCESS:
    case workflowTypes.GET_NODES_FAILURE:
    case workflowTypes.CREATE_WORKFLOW_SUCCESS:
    case workflowTypes.CREATE_WORKFLOW_FAILURE:
    case workflowTypes.UPDATE_WORKFLOW_SUCCESS:
    case workflowTypes.UPDATE_WORKFLOW_FAILURE:
    case workflowTypes.DELETE_WORKFLOW_SUCCESS:
    case workflowTypes.DELETE_WORKFLOW_FAILURE:
    case workflowTypes.DUPLICATE_WORKFLOW_SUCCESS:
    case workflowTypes.DUPLICATE_WORKFLOW_FAILURE:
    case workflowTypes.GET_WORKFLOWS_SUCCESS:
    case workflowTypes.GET_WORKFLOWS_FAILURE:
    case workflowTypes.DELETE_NODE_SUCCESS:
    case workflowTypes.DELETE_NODE_FAILURE:
    case accountTypes.EDIT_ORGANIZATION_SUCCESS:
    case accountTypes.EDIT_ORGANIZATION_FAILURE:
    case accountTypes.EDIT_TEAM_SUCCESS:
    case accountTypes.EDIT_TEAM_FAILURE:
    case accountTypes.CREATE_ORGANIZATION_SUCCESS:
    case accountTypes.CREATE_ORGANIZATION_FAILURE:
    case accountTypes.CREATE_TEAM_SUCCESS:
    case accountTypes.CREATE_TEAM_FAILURE:
    case accountTypes.CREATE_USER_SUCCESS:
    case accountTypes.CREATE_USER_FAILURE:
    case accountTypes.EDIT_USER_SUCCESS:
    case accountTypes.EDIT_USER_FAILURE:
    case accountTypes.CHANGE_USER_PASSWORD_FAILURE:
    case accountTypes.CHANGE_USER_PASSWORD_SUCCESS:
    case accountTypes.UPDATE_USER_SUCCESS:
    case accountTypes.UPDATE_USER_FAILURE:
    case accountTypes.UPDATE_PASSWORD_SUCCESS:
    case accountTypes.UPDATE_PASSWORD_FAILURE:
    case wizardTypes.PROCESS_WELLS_SUCCESS:
    case wizardTypes.PROCESS_WELLS_FAILURE:
    case resourcesTypes.DELETE_RESOURCE_SUCCESS:
    case resourcesTypes.DELETE_RESOURCE_FAILURE: {
      return {
        ...state,
        fullScreenLoading: state.fullScreenLoading - 1
      };
    }
    case workflowTypes.UPDATE_SELECTION_POSITION_REQUEST:
    case workflowTypes.CREATE_NODE_REQUEST:
    case workflowTypes.CREATE_EDGE_REQUEST:
    case workflowTypes.DELETE_EDGE_REQUEST:
    case workflowTypes.STOP_WORKFLOW_REQUEST:
    case workflowTypes.PAUSE_WORKFLOW_REQUEST:
    case workflowTypes.START_WORKFLOW_REQUEST:
    case workflowTypes.COPY_NODES_EDGES_REQUEST: {
      return {
        ...state,
        snackbarLoading: state.snackbarLoading + 1,
        snackbarMessage: 'Saving'
      };
    }

    case workflowTypes.WORKFLOW_VALIDATION_REQUEST: {
      return {
        ...state,
        snackbarLoading: state.snackbarLoading + 1,
        snackbarMessage: 'Validating'
      };
    }

    case accountTypes.LIST_ORGANIZATION_REQUEST:
    case accountTypes.LIST_TEAM_REQUEST:
    case accountTypes.LIST_USERS_REQUEST: {
      return {
        ...state,
        snackbarLoading: state.snackbarLoading + 1,
        snackbarMessage: 'Retrieving data'
      };
    }
    case loginTypes.MFA_CHALLENGE_REQUEST:
    case loginTypes.LOGIN_REQUEST: {
      return {
        ...state,
        snackbarLoading: state.snackbarLoading + 1,
        snackbarMessage: 'Logging in...'
      };
    }
    case loginTypes.MFA_CHALLENGE_SUCCESS:
    case loginTypes.MFA_CHALLENGE_FAILURE:
    case loginTypes.MFA_SETUP_SUCCESS:
    case loginTypes.MFA_SETUP_FAILURE:
    case workflowTypes.CREATE_NODE_FAILURE:
    case workflowTypes.CREATE_NODE_SUCCESS:
    case workflowTypes.CREATE_EDGE_FAILURE:
    case workflowTypes.CREATE_EDGE_SUCCESS:
    case workflowTypes.DELETE_EDGE_FAILURE:
    case workflowTypes.DELETE_EDGE_SUCCESS:
    case workflowTypes.STOP_WORKFLOW_SUCCESS:
    case workflowTypes.STOP_WORKFLOW_FAILURE:
    case workflowTypes.PAUSE_WORKFLOW_SUCCESS:
    case workflowTypes.PAUSE_WORKFLOW_FAILURE:
    case workflowTypes.START_WORKFLOW_SUCCESS:
    case workflowTypes.START_WORKFLOW_FAILURE:
    case workflowTypes.WORKFLOW_VALIDATION_FAILURE:
    case workflowTypes.WORKFLOW_VALIDATION_SUCCESS:
    case workflowTypes.WORKFLOW_VALIDATION_PENDING:
    case workflowTypes.COPY_NODES_EDGES_FAILURE:
    case workflowTypes.COPY_NODES_EDGES_SUCCESS:
    case accountTypes.LIST_ORGANIZATION_SUCCESS:
    case accountTypes.LIST_ORGANIZATION_FAILURE:
    case accountTypes.LIST_TEAM_SUCCESS:
    case accountTypes.LIST_TEAM_FAILURE:
    case accountTypes.LIST_USERS_SUCCESS:
    case accountTypes.LIST_USERS_FAILURE:
    case workflowTypes.UPDATE_SELECTION_POSITION_SUCCESS:
    case workflowTypes.UPDATE_SELECTION_POSITION_FAILURE:
    case loginTypes.LOGIN_SUCCESS:
    case loginTypes.LOGIN_MFA_CHALLENGE:
    case loginTypes.LOGIN_MFA_SETUP:
    case loginTypes.LOGIN_FAILURE: {
      return {
        ...state,
        snackbarLoading: Math.max(state.snackbarLoading - 1, 0)
      };
    }
    case workflowTypes.LIST_NODE_OUTPUT_REQUEST: {
      return {
        ...state,
        dialogLoading: state.dialogLoading + 1
      };
    }
    case workflowTypes.LIST_NODE_OUTPUT_FAILURE:
    case workflowTypes.LIST_NODE_OUTPUT_SUCCESS: {
      return {
        ...state,
        dialogLoading: Math.max(state.dialogLoading - 1, 0)
      };
    }
    case ENQUEUE_SNACKBAR_REQUEST:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: payload.key,
            ...payload.notification
          }
        ]
      };

    case CLOSE_SNACKBAR_REQUEST:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          payload.dismissAll || notification.key === payload.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      };

    case REMOVE_SNACKBAR_REQUEST:
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.key !== payload.key)
      };
    default:
      return state;
  }
}

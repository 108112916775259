/* eslint-disable camelcase */
import { ENQUEUE_SNACKBAR_REQUEST, CLOSE_SNACKBAR_REQUEST, REMOVE_SNACKBAR_REQUEST } from './ui.types';

export const enqueueSnackbar = (notification) => {
  return async (dispatch) => {
    const key = notification.options && notification.options.key;

    dispatch({
      type: ENQUEUE_SNACKBAR_REQUEST,
      payload: {
        notification: { ...notification, key: key || new Date().getTime() + Math.random() }
      }
    });
  };
};

export const closeSnackbar = (key) => ({
  type: CLOSE_SNACKBAR_REQUEST,
  payload: {
    dismissAll: !key, // dismiss all if no key has been defined
    key
  }
});

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR_REQUEST,
  payload: { key }
});

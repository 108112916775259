/* eslint-disable camelcase */
import * as types from './workflowLogs.types';
import client from 'utils/workflowClient';
import { extractErrorMessage } from 'utils/apiError';

const getWorkflowLogs = async (workflowDesignId) => {
  const { data } = await client.get(`/workflows/${workflowDesignId}/logs`);

  return data;
};

export const listWorkflowLogs = ({ workflowDesignId }) => {
  return async (dispatch) => {
    dispatch({
      type: types.LIST_WORKFLOW_LOGS_REQUEST
    });

    try {
      const { logs } = await getWorkflowLogs(workflowDesignId);

      dispatch({
        type: types.LIST_WORKFLOW_LOGS_SUCCESS,
        payload: {
          logs
        }
      });
    } catch (err) {
      return dispatch({
        type: types.LIST_WORKFLOW_LOGS_FAILURE,
        payload: {
          error: err.response?.data?.error || err.message
        }
      });
    }
  };
};

export const clearWorkflowLogs = () => {
  return async (dispatch) => {
    return dispatch({
      type: types.CLEAR_WORKFLOW_LOGS
    });
  };
};

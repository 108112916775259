import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

export const FullScreenLoaderPropTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function FullScreenLoader({ open, onClose }) {
  const classes = useStyles();
  return (
    <Backdrop data-testid="FullScreenLoader" className={classes.backdrop} open={!!open} onClick={onClose}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
FullScreenLoader.propTypes = FullScreenLoaderPropTypes;

FullScreenLoader.defaultProps = {
  open: false,
  onClose: () => {}
};

export default FullScreenLoader;

export const URM_SUPER_ADMIN = 1;
export const URM_ADMIN = 2;
export const URM_REGULAR = 3;

function isSuperAdmin(user) {
  return user.role && user.role.id == URM_SUPER_ADMIN;
}

function isAdmin(user) {
  return user.role && user.role.id == URM_ADMIN;
}

function isRegular(user) {
  return user.role && user.role.id == URM_REGULAR;
}

export function checkPermissions(permissionName, user, data) {
  if (!user || !permissionName) {
    return false;
  }

  switch (permissionName) {
    case 'list_organizations':
      return isSuperAdmin(user) || isAdmin(user);
    case 'create_organization':
      return isSuperAdmin(user);
    case 'edit_organization':
      return isSuperAdmin(user);
    case 'create_team':
      return isAdmin(user) || isSuperAdmin(user);
    case 'create_user':
      return isAdmin(user) || isSuperAdmin(user);
    case 'edit_organization':
      return isAdmin(user) || isSuperAdmin(user);
    case 'edit_team':
      return isAdmin(user) || isSuperAdmin(user);
    case 'edit_user':
      return isAdmin(user) || isSuperAdmin(user);
    case 'create_superadmin':
      return isSuperAdmin(user);
    case 'edit_superadmin':
      return isSuperAdmin(user);
  }
}

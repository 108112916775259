import * as types from './wellTie.types';

export const initialState = {
  process: [],
  loading: false,
  error: null,
  currentJobId: null,
  currentJobStatus: null
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.UPDATE_PROCESS_REQUEST:
    case types.LIST_JOB_STATUS_REQUEST:
    case types.GET_JOB_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case types.UPDATE_PROCESS_SUCCESS: {
      return {
        ...state,
        currentJobId: payload.job_id,
        currentJobStatus: 'INITIAL'
      };
    }
    case types.LIST_JOB_STATUS_SUCCESS: {
      return {
        ...state,
        currentJobStatus: payload.status
      };
    }
    case types.GET_JOB_SUCCESS: {
      return {
        ...state,
        process: payload.process,
        loading: false
      };
    }
    case types.UPDATE_PROCESS_FAILURE:
    case types.LIST_JOB_STATUS_FAILURE:
    case types.GET_JOB_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    }
    case types.CLEAR_ERROR: {
      return {
        ...state,
        error: null
      };
    }
    case types.CLEAR_CURRENT_JOB_STATUS: {
      return {
        ...state,
        currentJobStatus: null
      };
    }
    default:
      return state;
  }
}

import * as types from './workflowLogs.types';

export const initialState = {
  logs: [],
  loading: false,
  error: null
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.LIST_WORKFLOW_LOGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case types.LIST_WORKFLOW_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        logs: payload.logs
      };

    case types.LIST_WORKFLOW_LOGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    case types.CLEAR_WORKFLOW_LOGS:
      return initialState;
    default:
      return state;
  }
}

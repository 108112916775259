/* eslint-disable camelcase */
import * as types from './wellTie.types';
import client from 'utils/wellTieClient';
import { extractErrorMessage } from 'utils/apiError';

export const updateProcess = ({ inputs, configuration }) => {
  return async (dispatch) => {
    dispatch({
      type: types.CLEAR_CURRENT_JOB_STATUS
    });
    dispatch({
      type: types.UPDATE_PROCESS_REQUEST
    });
    try {
      const { data = {} } = await client.post('/wells/process', { inputs, configuration });

      dispatch({
        type: types.UPDATE_PROCESS_SUCCESS,
        payload: {
          ...data
        }
      });

      if (data.job_id) {
        dispatch(listJobStatus({ job_ids: [data.job_id] }));
      }
    } catch (err) {
      return dispatch({
        type: types.UPDATE_PROCESS_FAILURE,
        payload: {
          error: extractErrorMessage(err)
        }
      });
    }
  };
};

const listenForJobStatus = async (job_ids) => {
  const PROCESSING_STATUS = 'PROCESSING';
  const SUCCESS = 'SUCCESS';

  var status = PROCESSING_STATUS;

  while (status !== SUCCESS) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const { data } = await client.post('/jobs/status', { job_ids });

    status = data[0].status.name;
  }

  return status;
};

export const listJobStatus = ({ job_ids }) => {
  return async (dispatch) => {
    dispatch({
      type: types.LIST_JOB_STATUS_REQUEST
    });
    try {
      const status = await listenForJobStatus(job_ids);

      dispatch({
        type: types.LIST_JOB_STATUS_SUCCESS,
        payload: {
          status
        }
      });
    } catch (err) {
      return dispatch({
        type: types.LIST_JOB_STATUS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const clearError = () => {
  return async (dispatch) => {
    dispatch({
      type: types.CLEAR_ERROR
    });
  };
};

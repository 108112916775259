import { createInstance } from '@datapunt/matomo-tracker-react';

const instance = createInstance({
  urlBase: process.env.NEXT_PUBLIC_MATOMO_URL_BASE,
  siteId: process.env.NEXT_PUBLIC_MATOMO_SITE_ID,
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST'
  }
});

export default instance;
export { instance };

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENV = process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV;
const SENTRY_TRACES_SAMPLE_RATE =
  process.env.SENTRY_TRACES_SAMPLE_RATE || process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE;
const NEXT_PUBLIC_SENTRY_RELEASE_DATE = process.env.NEXT_PUBLIC_SENTRY_RELEASE_DATE;
const NEXT_PUBLIC_BUILD_ID = process.env.NEXT_PUBLIC_BUILD_ID;

if (SENTRY_DSN && SENTRY_ENV && NEXT_PUBLIC_SENTRY_RELEASE_DATE && SENTRY_TRACES_SAMPLE_RATE) {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://73b07e7686d047218dbc574d6de438b1@o1183152.ingest.sentry.io/6300254',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    environment: SENTRY_ENV ?? 'debug',
    release: NEXT_PUBLIC_SENTRY_RELEASE_DATE + '-' + NEXT_PUBLIC_BUILD_ID.substr(0, 4)
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}

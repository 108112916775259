import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { checkPermissions } from 'utils/authorizer';

const UserAuthContext = React.createContext(undefined);

const AuthProvider = ({ children }) => {
  const user = useSelector((state) => state.login.userDetails);
  const hasPermission = (name, data) => {
    return checkPermissions(name, user, data);
  };

  const data = [user, hasPermission];

  return <UserAuthContext.Provider value={data}>{children}</UserAuthContext.Provider>;
};

const useAuth = () => {
  const context = useContext(UserAuthContext);
  if (context === undefined) {
    throw new Error('useAuth can only be used inside AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };

import * as types from './preprocessing.types';

export const initialState = {
  sessions: [],
  loading: false,
  error: null
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.LIST_SESSIONS_REQUEST:
    case types.UPDATE_SESSION_REQUEST:
    case types.CREATE_SESSION_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case types.LIST_SESSIONS_SUCCESS:
    case types.UPDATE_SESSION_SUCCESS:
    case types.CREATE_SESSION_SUCCESS: {
      return {
        ...state,
        sessions: payload.sessions,
        loading: false
      };
    }
    case types.LIST_SESSIONS_FAILURE:
    case types.CREATE_SESSION_FAILURE:
    case types.UPDATE_SESSION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    }
    case types.CLEAR_ERROR: {
      return {
        ...state,
        error: null
      };
    }
    default:
      return state;
  }
}

const extractErrorMessage = (error) => {
  let errorMessage = 'Something went wrong with the request.';

  if (error.response && error.response.data) {
    errorMessage = err.response.data.error;
  } else if (error.message) {
    errorMessage = error.message;
  }

  return errorMessage;
};

export default extractErrorMessage;
export { extractErrorMessage };

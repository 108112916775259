/* eslint-disable camelcase */
import {
  UPDATE_CONNETION_STATUS_REQUEST,
  UPDATE_CONNETION_STATUS_SUCCESS,
  UPDATE_CONNETION_STATUS_FAILURE,
  GET_MESSAGE_REQUEST,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_FAILURE
} from './websocket.types';

export const updateConnection = ({ connected }) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_CONNETION_STATUS_REQUEST
    });

    try {
      return dispatch({
        type: UPDATE_CONNETION_STATUS_SUCCESS,
        payload: {
          connected
        }
      });
    } catch (err) {
      return dispatch({
        type: UPDATE_CONNETION_STATUS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const getMessage = ({ action, payload }) => {
  return async (dispatch) => {
    dispatch({
      type: GET_MESSAGE_REQUEST
    });

    try {
      return dispatch({
        type: GET_MESSAGE_SUCCESS,
        payload: payload
      });
    } catch (err) {
      return dispatch({
        type: GET_MESSAGE_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

const prefix = '@qes/wellTie';

export const UPDATE_PROCESS_REQUEST = `${prefix}/UPDATE_PROCESS_REQUEST`;
export const UPDATE_PROCESS_SUCCESS = `${prefix}/UPDATE_PROCESS_SUCCESS`;
export const UPDATE_PROCESS_FAILURE = `${prefix}/UPDATE_PROCESS_FAILURE`;

export const LIST_JOB_STATUS_REQUEST = `${prefix}/LIST_JOB_STATUS_REQUEST`;
export const LIST_JOB_STATUS_SUCCESS = `${prefix}/LIST_JOB_STATUS_SUCCESS`;
export const LIST_JOB_STATUS_FAILURE = `${prefix}/LIST_JOB_STATUS_FAILURE`;

export const GET_JOB_REQUEST = `${prefix}/GET_JOB_REQUEST`;
export const GET_JOB_SUCCESS = `${prefix}/GET_JOB_SUCCESS`;
export const GET_JOB_FAILURE = `${prefix}/GET_JOB_FAILURE`;

export const CLEAR_ERROR = `${prefix}/CLEAR_ERROR`;

export const CLEAR_CURRENT_JOB_STATUS = `${prefix}/CLEAR_CURRENT_JOB_STATUS`;

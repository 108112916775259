import React, { useContext } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import instance from 'utils/matomoInstance';
import CssBaseline from '@material-ui/core/CssBaseline';
import initAxios from 'utils/initAxios';
import store from 'store';
import WSProvider from 'components/WorkflowCanvas/WSProvider';
import FullScreenLoader from 'components/Common/FullScreenLoader';
import SnackbarLoader from 'components/Common/SnackbarLoader';
import ResourceUploader from 'components/Database/ResourceUploader';
import Notifier from 'components/Common/Notifier';
import VersionReloader from 'components/Common/VersionReloader';
import { AuthProvider } from 'providers/AuthProvider';
import { ThemeContext, ToggleThemeProvider } from 'providers/ToggleThemeProvider';
import NoSSR from 'utils/noSSR';
import Skeleton from 'components/Navigation/Skeleton'

// Init Axios
initAxios();


function MyApp(props) {
  const { Component, pageProps } = props;
  const fullScreenLoading = useSelector((state) => state.ui.fullScreenLoading);
  const snackbarLoading = useSelector((state) => state.ui.snackbarLoading);
  const snackbarMessage = useSelector((state) => state.ui.snackbarMessage);
  const resources = useSelector((state) => state.resources);
  const {getThemeName, getTheme} = useContext(ThemeContext);
  const [theme, setLocalTheme] = React.useState(getTheme(getThemeName()));

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const updateTheme = (updatedTheme) => {
    setLocalTheme(updatedTheme);
  }


  return (
    <React.Fragment>
      <MatomoProvider value={instance}>
        <ToggleThemeProvider callback={updateTheme}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
              <WSProvider>
                <AuthProvider>
                  <Head></Head>
                  <VersionReloader />
                  <NoSSR>
                    <Skeleton>
                      <Component {...pageProps} />
                    </Skeleton>
                  </NoSSR>
                  <FullScreenLoader open={Boolean(fullScreenLoading)} />
                  <SnackbarLoader open={Boolean(snackbarLoading)} message={snackbarMessage} />
                  <ResourceUploader uploads={resources.uploads} />
                  <Notifier />
                </AuthProvider>
              </WSProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </ToggleThemeProvider>
      </MatomoProvider>
    </React.Fragment>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired
};

export default store.withRedux(MyApp);

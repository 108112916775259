import { GET_DATA_REQUEST, GET_DATA_SUCCESS, GET_DATA_FAILURE } from './visualizations.types';

export const initialState = {
  loading: false,
  error: null
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    default:
      return state;
  }
}

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateConnection } from 'store/modules/websocket';
import { enqueueSnackbar } from 'store/modules/ui';
import { syncCurrentWorkflow, getWorkflow } from 'store/modules/workflows';

export const WSProviderPropTypes = {};

const mappings = {
  NODE_STARTED: (payload) => `${payload.workflowDesignName} - "${payload.nodeTitle}" Started`,
  NODE_SUCCESS: (payload) => `${payload.workflowDesignName} - "${payload.nodeTitle}" Succeeded`,
  NODE_FAILURE: (payload) => `${payload.workflowDesignName} - "${payload.nodeTitle}" Failed`,
  WORKFLOW_STARTED: (payload) => `${payload.workflowDesignName} Started`,
  WORKFLOW_SUCCESS: (payload) => `${payload.workflowDesignName} Succeeded`,
  WORKFLOW_FAILURE: (payload) => `${payload.workflowDesignName} Failed`
};

const types = {
  NODE_STARTED: 'node',
  NODE_SUCCESS: 'node',
  NODE_FAILURE: 'node',
  WORKFLOW_STARTED: 'workflow',
  WORKFLOW_SUCCESS: 'workflow',
  WORKFLOW_FAILURE: 'workflow'
};

const composeNotificationMessage = (action, payload) => {
  return mappings[action](payload);
};

function WSProvider({ children }) {
  const user = useSelector((state) => state.login.userDetails);
  const ws = React.useRef(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    if (open) return;
    ws.current = new WebSocket(process.env.NEXT_PUBLIC_WS_URL);
    let intervalId = null;
    ws.current.onopen = () => {
      dispatch(updateConnection({ connected: true }));
      intervalId = setInterval(() => {
        ws.current.send(JSON.stringify({ keepAlive: true }));
        setOpen(true);
      }, 540000);
      if (user) {
        const teamIds = user.teams.map((team) => team.id);
        ws.current.send(JSON.stringify({ setConnectionUserInfo: { userId: user.id, teamIds } }));
      } else {
        console.log('no user details found');
      }
    };
    ws.current.onerror = (err) => {
      console.error(err);
      //workaround to sync workflow status and informations when there's an error on the websocket connection
      dispatch(syncCurrentWorkflow());
    };
    ws.current.onclose = () => {
      if (intervalId) clearInterval(intervalId);
      dispatch(updateConnection({ connected: false }));
      setOpen(false);
    };

    const endConnection = () => {
      if (ws.current) ws.current.close();
      window.removeEventListener('beforeunload', beforeUnload);
    };

    const beforeUnload = (e) => {
      event.preventDefault();
      return endConnection();
    };

    const listenersSetup = () => {
      window.addEventListener('beforeunload', beforeUnload);
    };
    ws.current.onmessage = (e) => {
      const message = JSON.parse(e.data);
      const { action, payload } = message;
      if (!action) {
        return;
      }
      dispatch(enqueueSnackbar({ message: composeNotificationMessage(action, payload), variant: 'info' }));
      dispatch(getWorkflow({ workflowId: payload.workflowDesignId }));
    };

    listenersSetup();
    return () => endConnection();
  }, [open]);

  return children;
}
WSProvider.propTypes = WSProviderPropTypes;

WSProvider.defaultProps = {};

export default WSProvider;

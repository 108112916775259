/* eslint-disable camelcase */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { injectAuthorizationToken, refreshToken } from './initAxios';

const mlflowServiceBaseUrl = process.env.NEXT_PUBLIC_MLFLOW_SERVICE_URL;
const client = axios.create({
  baseURL: mlflowServiceBaseUrl
});

client.interceptors.request.use(injectAuthorizationToken);
createAuthRefreshInterceptor(client, refreshToken);

export default client;

import * as types from './models.types';

export const initialState = {
  experiments: [],
  runs: [],
  loading: false,
  error: null
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_EXPERIMENTS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case types.GET_EXPERIMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        experiments: payload.experiments
      };
    }
    case types.GET_EXPERIMENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    }
    case types.GET_RUNS_REQUEST: {
      return {
        ...state,
        loading: true,
        runs: [],
        error: null
      };
    }
    case types.GET_RUNS_SUCCESS: {
      return {
        ...state,
        loading: false,
        runs: payload.runs
      };
    }
    case types.GET_RUNS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    }
    default:
      return state;
  }
}

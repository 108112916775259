import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './SnackbarLoader.styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgress from '@material-ui/core/CircularProgress';

export const SnackbarLoaderPropTypes = {
  open: PropTypes.bool,
  message: PropTypes.string
};

function SnackbarLoader({ open, message }) {
  const classes = useStyles();

  return (
    <Snackbar
      data-testid="SnackbarLoader"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={!!open}
      autoHideDuration={6000}
    >
      <SnackbarContent
        message={message || 'Saving'}
        action={<CircularProgress size={20} color="secondary" />}
        className={classes.snackbar}
      />
    </Snackbar>
  );
}
SnackbarLoader.propTypes = SnackbarLoaderPropTypes;

SnackbarLoader.defaultProps = {
  open: false,
  message: 'Saving'
};

export default SnackbarLoader;

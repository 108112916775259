import {
  UPDATE_CONNETION_STATUS_REQUEST,
  UPDATE_CONNETION_STATUS_SUCCESS,
  UPDATE_CONNETION_STATUS_FAILURE,
  GET_MESSAGE_REQUEST,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_FAILURE
} from './websocket.types';

export const initialState = {
  connected: false,
  error: null,
  loading: false
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case UPDATE_CONNETION_STATUS_REQUEST:
    case GET_MESSAGE_REQUEST: {
      return {
        loading: true,
        error: null
      };
    }
    case UPDATE_CONNETION_STATUS_SUCCESS: {
      return {
        connected: payload.connected,
        loading: false,
        error: null
      };
    }
    case GET_MESSAGE_SUCCESS: {
      return {
        loading: false,
        error: null
      };
    }
    case UPDATE_CONNETION_STATUS_FAILURE:
    case GET_MESSAGE_FAILURE: {
      return {
        loading: false,
        error: payload.error
      };
    }
    default:
      return state;
  }
}

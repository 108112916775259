const prefix = '@qes/preprocessing';

export const LIST_SESSIONS_REQUEST = `${prefix}/LIST_SESSIONS_REQUEST`;
export const LIST_SESSIONS_SUCCESS = `${prefix}/LIST_SESSIONS_SUCCESS`;
export const LIST_SESSIONS_FAILURE = `${prefix}/LIST_SESSIONS_FAILURE`;

export const CREATE_SESSION_REQUEST = `${prefix}/CREATE_SESSION_REQUEST `;
export const CREATE_SESSION_SUCCESS = `${prefix}/CREATE_SESSION_SUCCESS `;
export const CREATE_SESSION_FAILURE = `${prefix}/CREATE_SESSION_FAILURE `;

export const UPDATE_SESSION_REQUEST = `${prefix}/UPDATE_SESSION_REQUEST`;
export const UPDATE_SESSION_SUCCESS = `${prefix}/UPDATE_SESSION_SUCCESS`;
export const UPDATE_SESSION_FAILURE = `${prefix}/UPDATE_SESSION_FAILURE`;

export const CLEAR_ERROR = `${prefix}/CLEAR_ERROR`;

import React, { useState, useEffect, useRef } from 'react';
import Dialog from 'components/Common/Dialog';
import { useRouter } from 'next/router';

function useInterval(callback, { interval, lead }) {
  const savedCallback = useRef(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    lead && tick();

    if (interval !== null) {
      const id = setInterval(tick, interval);

      return () => clearInterval(id);
    }
  }, [interval]);
}

function VersionReloader() {
  const router = useRouter();
  const [showDialog, setShowDialog] = useState(false);

  const reload = () => {
    router.reload();
  };

  useInterval(
    async () => {
      const request = await fetch('/api/build-id');
      const { buildId } = await request.json();
      if (buildId && process.env.NEXT_PUBLIC_BUILD_ID && buildId !== process.env.NEXT_PUBLIC_BUILD_ID) {
        setShowDialog(true);
      }
    },
    { interval: 60000 }
  );

  return showDialog ? (
    <Dialog
      open={!!showDialog}
      maxWidth="xs"
      title="New version available"
      body="Please reload the browser to get the latest version"
      confirm="Reload"
      onClose={reload}
      onConfirm={reload}
    ></Dialog>
  ) : null;
}

VersionReloader.propTypes = {};
VersionReloader.defaultProps = {};

export default VersionReloader;

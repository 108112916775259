/* eslint-disable camelcase */
// import _ from 'lodash';
import client from 'utils/mlflowClient';
import * as types from './models.types';

export const getExperiments = () => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_EXPERIMENTS_REQUEST
    });
    try {
      const { data = {} } = await client.get('/experiments');

      dispatch({
        type: types.GET_EXPERIMENTS_SUCCESS,
        payload: {
          experiments: data
        }
      });
    } catch (err) {
      return dispatch({
        type: types.GET_EXPERIMENTS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const getRuns = (experiment_id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_RUNS_REQUEST
    });
    try {
      let url = '/runs';
      if (experiment_id) {
        url = `/runs?experiment_id=${experiment_id}`;
      }

      const { data = {} } = await client.get(url);

      dispatch({
        type: types.GET_RUNS_SUCCESS,
        payload: {
          runs: data
        }
      });
    } catch (err) {
      return dispatch({
        type: types.GET_RUNS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};
